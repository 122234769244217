import React, { useState, useRef } from "react";
import { BrowserRouter as Router, Route, Routes, useNavigate } from "react-router-dom";
import { JoinUsNavigation } from "../components/JoinUsNavigation";
import SeniorDeveloperRole from "./roles/SeniorDevRole";
import BusinessOpsSpecialist from "./roles/BusinessOpsSpecialist";
import SeniorBusinessDeveloperManager from "./roles/SeniorBusinessDeveloperManager";
import CodeIcon from "@mui/icons-material/Code";
import "../website-style.css";

const roles = [
  {
    id: "senior-dev-role",
    name: "Senior Developer",
    description: "Got those coding skills? We are looking for a Senior Javascript Developer to join our team.",
    component: SeniorDeveloperRole,
    image: <CodeIcon />,
  },
  {
    id: "business-ops-specialist",
    name: "Product and Business Operations Specialist",
    description: "Are you a business operations specialist? We are looking for you!",
    component: BusinessOpsSpecialist,
  },
  {
    id: "senior-bd-manager",
    name: "Senior Business Developer Manager",
    description:
      "Got a passion for business development and want to work in Sustainability? Maybe you should apply for this role!",
    component: SeniorBusinessDeveloperManager,
  },
];

export default function JoinCirculand() {
  const [selectedRole, setSelectedRole] = useState(null);
  const navigate = useNavigate();
  const mainRef = useRef(null); // Create a ref for the main div

  const renderRoleDetails = () => {
    if (!selectedRole) return <p> Click on a role to apply!</p>;
    const RoleComponent = roles.find((role) => role.id === selectedRole).component;
    return <RoleComponent />;
  };

  const handleRoleSelect = (role) => {
    setSelectedRole(role.id);
    navigate(`/careers/${role.id}`);
  };

  const renderRoles = () => {
    const isMobile = window.innerWidth < 800;

    if (isMobile) {
      return (
        <select onChange={(e) => handleRoleSelect(roles.find((role) => role.id === e.target.value))} className="mobile-dropdown">
          <option value="">Select a role...</option>
          {roles.map((role) => (
            <option key={role.id} value={role.id}>
              {role.name}
            </option>
          ))}
        </select>
      );
    } else {
      return roles.map((role) => (
        <div key={role.id} className="post-container" onClick={() => handleRoleSelect(role)}>
          <div className="post-header">
            <div className="post-info">
              <h3>{role.name}</h3>
              <p>{role.description}</p>
            </div>
          </div>
        </div>
      ));
    }
  };

  return (
    <div className="join-circuland-container" style={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}>
      <JoinUsNavigation />
      <div id="home" className="section-container section-container-HOME" style={{ flex: 1 }}>
        <div className="page">
          <div
            id="homeunderlay"
            className="page-underlay"
            style={{
              margin: "40px 0px 80px 0px",
            }}
          >
            <div className="grid-Text-careers">
              <div className="paragraph-text">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  {renderRoles()}
                </div>
              </div>
              <div className="main" ref={mainRef}>
                {/* Attach ref here */}
                <JoinUsNavigation />
                <Routes>
                  <Route path="/business-ops-specialist" element={<BusinessOpsSpecialist />} />
                  <Route path="/senior-dev-role" element={<SeniorDeveloperRole />} />
                  <Route path="/senior-bd-manager" element={<SeniorBusinessDeveloperManager />} />
                </Routes>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="footer" style={{ backgroundColor: "rgb(2, 117, 133)", color: "white", width: "100%" }}>
        <div className="container text-center">
          <p className="ptext" style={{ color: "white" }}>
            &#169; Circuland 2022 - 2025 All Rights Reserved.
            <br />
            CIRCULAND LTD Company number 14353001
            <br />
            <a href="/privacyPolicy" className="ptext" style={{ color: "white", marginRight: "10px" }}>
              <b> Privacy Policy Link</b>
            </a>
            |
            <a href="/termsAndConditions" className="ptext" style={{ color: "white", marginLeft: "10px" }}>
              <b> Terms and Conditions Link</b>
            </a>
            <br />
            Careers @ Circuland V1.0.8
          </p>
        </div>
      </div>
    </div>
  );
}
