import { useState, useEffect, useLayoutEffect } from "react";
import App from "./App";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import { Routes, Route } from "react-router-dom";
import { initGA } from "./controller/ga-utils.ts";
import { Cookies } from "react-cookie-consent";
import Switch from "react-switch";
import TermsAndConditions from "./pages/TermsAndConditions";
import JoinCirculand from "./pages/JoinCirculand";
import { useSelector, useDispatch } from "react-redux";
import { set } from "./redux/generalUtils";
import { clarity } from "clarity-js";

function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(";").shift();
}

function setCookie(cname, cvalue, exdays) {
  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  let expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

export default function Website() {
  const dispatch = useDispatch();
  const [show_consent, set_show_consent] = useState(false);
  const [save_preferences, set_save_preferences] = useState(true);
  const [size, set_size] = useState([0, 0]);

  const generalUtils_window = useSelector((state) => state.generelUtils.value);

  useLayoutEffect(() => {
    function updateSize() {
      set_size([window.innerWidth, window.innerHeight]);

      if (generalUtils_window[0] !== window.innerWidth || generalUtils_window[1] !== window.innerHeight) {
        dispatch(set([window.innerWidth, window.innerHeight]));
      }
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  useEffect(() => {
    set_cookies_once_consent();
  }, []);

  const set_cookies_once_consent = () => {
    if (process.env.REACT_APP_GOOGLE_ANALYTICS_ID) {
      switch (process.env.NODE_ENV) {
        case "production":
          initGA(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);

          // NOTE - Helpful for setup: 'https://github.com/microsoft/clarity/issues/165'
          clarity.start({
            projectId: "klfgyrl6xe",
            upload: "https://m.clarity.ms/collect",
            track: true,
            content: true,
          });

          break;
        case "development":
          initGA(process.env.REACT_APP_GOOGLE_ANALYTICS_ID_DEVEN);

          clarity.start({
            projectId: "kl84vvqeiv",
            upload: "https://m.clarity.ms/collect",
            track: true,
            content: true,
          });
          break;
        default:
          break;
      }
    }
  };

  return (
    <>
      <Routes>
        <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
        <Route path="/TermsAndConditions" element={<TermsAndConditions />} />
        <Route path="/careers" element={<JoinCirculand />} />
        <Route path="/careers/*" element={<JoinCirculand />} />
        <Route path="/*" element={<App />} />
      </Routes>

      {show_consent && (
        <div className="">
          <div className="space-y-3">{/* TODO - fix this to be better UX for the user */}</div>
        </div>
      )}
    </>
  );
}
